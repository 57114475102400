<template>
  <main class="home" id="home">
    <Banner/>
    <AboutUs/>
    <!-- <Project/> -->
    <Contact/>
    <insight/>
    <Career/>
    <Testimonial/>
    <Footer/>
  </main>
</template>

<script>
import Banner from '../components/banner.vue';
import AboutUs from '../components/about_us.vue';
import Project from '../components/project.vue';
import Contact from '../components/contact.vue';
import insight from '../components/insight.vue';
import Career from '../components/career.vue';
import Testimonial from '../components/testimonial.vue';
import Footer from '../components/footer.vue';
export default {
  name: 'Home',
  metaInfo: {
        title: 'Home',
        meta: [
            {
              vmid: 'description',
                name: 'description',
                content: "Explore modern architecture and sustainable design with Atlanta's top residential and commercial architects. Discover innovative building materials, sustainable urbanism, and energy-efficient techniques. From historic preservation to contemporary skyscraper design, our firm specializes in custom home design, mixed-use development, and urban revitalization projects. Utilizing cutting-edge software like Revit, AutoCAD, and SketchUp, we create stunning 3D visualizations and implement green building practices for LEED certification. Whether you're seeking luxury homes, affordable housing, or adaptive reuse solutions, our team excels in creating functional and aesthetically pleasing spaces. Contact us today for expert architectural services in Atlanta, combining creativity, sustainability, and community engagement.",
            }
        ]
    },
  components: { AboutUs ,Project, Contact, insight, Career, Testimonial, Footer, Banner },
  data() {
    return {
    }
  },
    computed:{
    },
  methods: {
  }
}
</script>

<style>
  .section{
    width: 100vw;
    min-height: 100vh;
  }
  
</style>