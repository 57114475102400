<template>
    <footer class="">
        <div class="footer"></div>
        <div class="footer_down d-flex align-items-center w-100 ">
            <div class="w-100 d-grid  w-100 h-100 mb-3">
                <div class="d-md-flex d-grid justify-content-md-between justify-content-center align-items-center w-100 p-5">
                    <div class="logo_footer col-6 d-flex justify-content-md-start justify-content-center align-items-center">
                        <img src="../assets/logo/AP_Logo.webp" alt="logo" class="">
                    </div>
                    <div class="col-6 justify-content-md-end justify-content-center align-items-center d-flex p-2" >
                        <div class="text-start" style="width: 50%;">
                            <div class="my-2">
                                <b-icon icon="geo-alt-fill" style="color:var(--secondColor)"></b-icon>
                                <small class="mx-2 text-white">6330 Amherst CT, Norcross, GA 30092</small>
                            </div>
                            <div class="my-2">
                                <b-icon icon="telephone-fill" style="color:var(--secondColor)"></b-icon>
                                <small class="mx-2 text-white">+1 (404) 514-0911</small>
                            </div>
                            <div class="my-2">
                                <b-icon icon="envelope-fill" style="color:var(--secondColor)"></b-icon>
                                <small class="mx-2 text-white">Info@Archipars.com</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-center d-flex">
                    <div class="d-grid align-items-end w-100">
                        <div class="socialNetwork">
                            <ul class="d-flex box-center">
                                <li>
                                    <a href="https://www.facebook.com/archiparsarchitecture" target="_blank">
                                        <img src="../assets/icons/facebook.png" alt="facebook" style="width:1.5rem;height:1.5rem">
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/archipars/" target="_blank">
                                        <img src="../assets/icons/instagram.png" alt="instagram">
                                    </a>
                                </li>
                                <!-- <li>
                                    <a href="https://twitter.com/ArchiPars" target="_blank">
                                        <img src="../assets/icons/twitter.png" alt="twitter" >
                                    </a>
                                </li> -->
                                <!-- <li>
                                    <a href="https://www.pinterest.com/ArchiParsLLC/" target="_blank">
                                        <img src="../assets/icons/pinterest.png" alt="pinterest" style="width:1.5rem;height:1.5rem">
                                    </a>
                                </li> -->
                                <li>
                                    <a href="https://www.linkedin.com/company/archipars-llc/" target="_blank">
                                        <img src="../assets/icons/linkedin.png" alt="linkedin" style="width:1.5rem;height:1.5rem;margin: 0 .5rem;">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    <small class="text-white my-2">Copyright ©{{new Date().getFullYear() }} All rights reserved | Created by Amirali Khalajtehrani</small>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name:'Footer',
    data(){
        return{
        }
    }
}
</script>

<style >
    .footer{
        background-color: black;
        height: 6.1rem;
        -webkit-clip-path: polygon(0 315px, 100% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 94px, 100% 0px, 100% 100%, 0% 100%);
    }
    .footer_down{
        background-color: black;
        /* height: 12rem; */
    }
    .logo_footer{
        
        height: 100%;
        object-fit: contain;
    }
    .footer_down .logo_footer img{
        width: 10rem;
        height: 10rem;
        object-fit: contain;
    }
    .socialNetwork img{
        width: 2rem;
        height: 2rem;
        object-fit: contain;
    }
    .socialNetwork ul li{
        margin: 0 .25rem;
    }
    @media only screen and (max-width: 640px) {
        .footer_down .col-6{
            width: 100% !important;
        }
        .logo_footer{
            width: 100%;
        }
    }
    

</style>