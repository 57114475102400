<template>
    <section class="project_area">
        <div class="w-100 h-100 container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section_show_testimonial">
                        <hooper :wheelControl="false" :settings="testimonialSettings" :infiniteScroll="true" :autoPlay="true" :playSpeed="5000">
                                <slide v-for="testimonial in testimonialsData" :key="testimonial.id" class="mb-5">
                                    <div class="mx-4">
                                        <div class="d-flex img shadow testimonial_card p-2 box-center">
                                            <img :src="require(`../assets/Testimonials/${testimonial.image}`)" alt="">
                                        </div>
                                    </div>
                                </slide>

                                <hooper-pagination slot="hooper-addons" style="transform: translate(9.5rem, 4rem);"></hooper-pagination>
                            </hooper>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import 'animate.css';
import testimonials from '../../data/testimonial.json'
import 'hooper/dist/hooper.css';
import {
    Hooper,
    Slide,
    Pagination as HooperPagination,
} from 'hooper';

export default {
    name:'testimonial',
    components: {
        Hooper,
        Slide,
        HooperPagination,
        testimonials
    },
    data() {
        return {
            hover: false,
            testimonialSettings: {
                itemsToShow: 1,
                centerMode: true,
                breakpoints: {
                    930: {
                        itemsToShow: 2,
                        centerMode: false,
                    },
                    394: {
                        itemsToShow: 1,
                        centerMode: false,
                    }
                }
            },
            testimonialsData : testimonials.testimonial,
            testimonials:null,
        }
    },

    methods: {}
}
</script>
<style>
.testimonial_area {
    padding-top: 5rem;
}
.testimonial_title h2{
    font-weight: 800;
    font-size: 35px;
}
.testimonial_title h5{
    font-weight: 500;
    color: var(--secondColor);
}
.hooper {
    height: 100% !important;
}

.testimonial_area .section_title h2 {
    font-size: 24px;
    font-weight: 700;
    position: relative;
    z-index: 1;
    text-transform: capitalize;
    line-height: 24px;
    display: inline-block;
    margin-bottom: 80px;
    right: 20rem;
}

/* .testimonial_card {
    height: 20rem;
    width: 35rem;
    object-fit: contain;
    border: 1px solid rgba(160, 160, 160, 0.26);

}
.testimonial_card img{
    height: 100%;
    width: 100%;
    object-fit: contain;
} */
.testimonial_card {
    height: 15rem;
    width: 25rem;
    border: 1px solid rgba(217, 217, 217, 0.26);
    border-radius: 10px;
    box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);


}
.testimonial_card img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

/* .img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.5rem;
    transition: all .3s ease;
} */


.testimonial_card .w-100 {
    height: 70%;
}

.more_btn {
    border-bottom: 2px solid transparent;
    transition: all .3s ease;
    font-weight: 500;
}

.more_btn:hover {
    border-bottom: 2px solid black;
}
.section_show_testimonial{
    height: 20rem;
}
.hooper-indicator:hover, .hooper-indicator.is-active {
    background-color: var(--secondColor);
}
.hooper-indicator {
    margin: 0 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: none;
    padding: 0;
    background-color: #cfcfcf;
    cursor: pointer;
}
@media only screen and (max-width: 640px) {
    .testimonial_area {
        padding-top: 12rem;
    }

    .testimonial_area .section_title h2 {
        right: 13rem;
    }

    .testimonial_area .section_title h2::after {
        width: 120px;
    }
    .testimonial_card {
    
        width: 100%;
    }
}</style>