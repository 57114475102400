<template>
    <section class="insight_area">
        <div class="w-100 h-100 container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="section_tittle px-4 mb-3">
                            <h2><span>INSIGHTS</span></h2>
                        </div>
                    </div>
                    <div class="section_show_insight mb-5">
                        <hooper :wheelControl="false" :settings="insightSetting" :infiniteScroll="true" :autoPlay="true" :playSpeed="10000">
                            <slide v-for="insight in showInsights()" :key="insight.id">
                                <div>
                                    <div class="insight_card mx-3 rounded-1 d-flex box-center ">
                                        <div class="d-grid justify-content-start align-items-center text-start p-3">
                                            <div class="insight_title my-2">
                                                <p class="insight_title_inner">{{insight.title}}</p>
                                            </div>
                                            <div class="">
                                                <span class="insight_dec">{{ insight.text1 }}</span>
                                            </div>
                                            <div class="my-3 ">
                                                <router-link :to="{name:'article',params:{id:insight.id}}" class=" d-flex justify-content-start align-items-center text-start read_more">
                                                    Read more
                                                    <b-icon icon="arrow-right" variant="dark" class="arrow_right mt-1 d-flex box-center"></b-icon>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </slide>
                        </hooper>
                    </div>
                    <div class="w-100 mt-5">
                        <router-link to="/insight" class="mt-2 more_btn p-2">
                            <span class="">More insights</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import 'hooper/dist/hooper.css';
import {Hooper,Slide,Pagination as HooperPagination} from 'hooper';
import Insights from '../../data/article.json'

export default {
    name:'insight',
    components: {Hooper,Slide,HooperPagination},
    data() {
        return {
            hover: false,
            insightSetting: {
                itemsToShow: 1,
                centerMode: true,
                breakpoints: {
                    930: {
                        itemsToShow: 3,
                        centerMode: false,
                    },
                    394: {
                        itemsToShow: 1,
                        centerMode: true,
                    }
                }
            },
            insightsData : Insights.insights,
        }
    },

    methods: {
        showInsights(){
            return this.insightsData.slice(0,5);
        }
    }
}
</script>
<style>
.insight_area {
    padding: 95px 0px 35px 0px;
}

.hooper {
    height: 100% !important;
}

.insight_area .section_title h2 {
    font-size: 24px;
    font-weight: 700;
    position: relative;
    z-index: 1;
    text-transform: capitalize;
    line-height: 24px;
    display: inline-block;
    margin-bottom: 80px;
    left:12rem;
}

.insight_area .section_title h2::before {
    position: absolute;
    top: 12px;
    right: 5rem;
    content: "";
    width: 150px;
    height: 1px;
    background-color: black
}

.insight_card {
    cursor:default;
}

.img {
    overflow: hidden;
    /* border-radius: 0.25rem; */
}

.insight_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* border-radius: 0.5rem; */
    transition: all .3s ease;
}

.insight_card:hover img {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    border-radius: 0.5rem;
}
.insight_title_inner{
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-height: 1.7rem;
    font-size: 1.5rem;
}
.insight_dec {
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 10;
    color: rgb(127, 127, 127);
    line-height: 1.7rem;
    font-size: 16px;
    text-align: justify;
}
.insight_title p{
    font-weight: 600;
}
.insight_card .w-100 {
    height: 70%;
}
.read_more{
    font-weight: 500;
}
.read_more .arrow_right{
    margin: 0 .75rem;
    transition: all .2s linear;
}
.read_more:hover .arrow_right{
    margin: 0 1rem;
}
.more_btn{
    border-bottom: 2px solid transparent;
    transition: all .3s ease;
    font-weight: 500;
}
.more_btn:hover{
    border-bottom: 2px solid black;
}
@media only screen and (max-width: 640px) {
    .insight_area {
        padding-top: 4rem;
    }

    .insight_area .section_title h2 {
        right: 13rem;
    }

    .insight_area .section_title h2::after {
        width: 120px;
    }
}</style>