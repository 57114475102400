<template>
    <section class="career_part d-flex">
        <div class="col-6">
            <img src="../assets/image/career.webp" alt="" style="filter: grayscale(1);">
        </div>
        <div class="col-6 d-flex box-center">
            <div class="d-flex box-center h-100 text-white career">
                <div class="w-75 d-grid text-center text-md-start h-75">
                    <div class="d-flex align-items-center">
                        <h2>BUILD YOUR CAREER WITH US</h2>
                    </div>
                    <div class="">
                        <p class="w-sm-100 w-75 text-justify mx-auto mx-md-0">
                            We grow our firm by growing our people — if you are a recent graduate or a seasoned designer who believes in the power of design, we invite you to search for opportunities and explore how you can reimagine the future with us.
                        </p>
                    </div>
                    <div class="">
                        <router-link to="/contact" class="rounded-3 p-2 join">
                            <span class="mx-2">Join our team</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name:'career',

}
</script>

<style>
.career_part {
    background-color: black; 
    width: 100%;
    height: 37rem;
    
}
.career_part img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.career h2{
    font-size: 35px;
    font-weight: 700;
}
.career p{
    line-height: 2rem;
    font-size: 18px;
}
.join{
    border: 2px solid #DCAC5C;
    color: #DCAC5C;
    font-weight: 500;
    transition: all .3s ease;
}
.join:hover{
    background-color: #DCAC5C;
    color: white;
}
@media only screen and (max-width: 640px) {
    .career_part{
        flex-direction: column;
    }
    .career_part {
        background-color: black;
        width: 100%;
        height: 60rem;
    }
    .career_part .col-6{
        width: 100% !important;
    }
    .w-sm-100{
        width: 100% !important;
    }
}
</style>