<template>
  <div id="app">
    <div v-if="showNav">
      <Navbar />
    </div>
      <router-view />
      <!-- <Footer/> -->
  </div>
</template>
<script>
import Navbar from './components/navbar.vue'
import Footer from './components/footer.vue'
export default {
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
    ]
  },
  components:{ Navbar,Footer },
  data() {
    return {
      showNav:true,
    }
  },
  mounted() {
    if (this.$route.name === "admin") {
      this.showNav = true;
    }
  },
  methods:{

  },
  watch:{
    $route (to, from){
      if (to.name === "admin" || to.name === "login") {
      this.showNav = false;
    }
    }
} 
}
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1C1C1C;
  width: 100%;
  height: 100%;
  
}

nav {
  padding: 30px;
  background-color: transparent;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
