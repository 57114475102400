<template>
    <section class="about_part section-padding">
        <div class="container">
            <div class="row">
                <div class="section_tittle px-4 mb-3">
                    <h2><span>ABOUT US</span></h2>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-6 col-md-6">
                    <div class="about_img">
                        <img src="../assets/image/about.webp" alt="">
                    </div>
                </div>
                <div class="offset-lg-1 col-lg-5 col-sm-8 col-md-6 d-flex justify-content-center align-items-start">
                    <div class="about_text my-5 my-md-0">
                        <div class="mb-md-4 d-flex justify-content-md-start justify-content-center text-start">
                            <h1>SPACES WHERE PEOPLE<br><span style="color:var(--secondColor)"> LIVE. WORK. CONNECT.</span></h1>
                        </div>
                        <div class="w-80 w-sm-100 p-3 p-md-0" style="text-align: justify;" >
                            <span data-aos="zoom-in-up" data-aos-duration="1500" class="my-0" style="line-height: 1.9rem;"> Founded in 2017, Archipars is a <b class="fs-5">boutique</b> Architecture firm located in Atlanta, Georgia. We have worked with a <b class="fs-5">diverse group</b> of clients on projects of <b class="fs-5">all sizes</b> and scope. We are driven by creating <b class="fs-5">beautiful</b> and <b class="fs-5">functional</b> spaces for <b class="fs-5">any purpose</b> and scale. Inspired by new challenges and possibilities in Architecture, we have the skillset, commitment, and vision to handle any project. </span>
                            <span data-aos="zoom-in-up" data-aos-duration="1500" class="my-0" style="line-height: 1.9rem;">Our mission is to create places where people <strong>live, work, and connect</strong> with one another while <strong>building community</strong>.<br>
                                As such, our key markets are:
                                <div class="mt-3" style="display: flex;gap: 5px;flex-direction: column;">
                                    <span data-aos="zoom-in" data-aos-delay="500" data-aos-duration="1500"><strong style="color:var(--secondColor)">RESIDENTIAL: </strong> Senior Living, Multi-family, High-End Single-Family<br></span>
                                    <span data-aos="zoom-in" data-aos-delay="1000" data-aos-duration="1500"><strong  style="color:var(--secondColor)">COMMERCIAL:</strong> Office, Retail, Restaurant, Hospitality<br></span>
                                    <span data-aos="zoom-in" data-aos-delay="1500" data-aos-duration="1500"><strong style="color:var(--secondColor)">GATHERING:</strong> Community Centers, Clubhouses, Public Spaces</span>
                                </div>
                                
                            </span>
                        </div>
                        <div class="w-100 d-flex justify-content-start align-items-center mt-3" data-aos="fade-up-right" data-aos-delay="1500" data-aos-duration="1000">
                            <router-link to="/about" class="py-2 px-4 rounded-2 bg-dark text-white mt-2">
                                Read more
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import 'animate.css';
export default {
    name:'about_us'
}
</script>

<style>
#about_us{
    width: 100%;
    height: 85%;
}
.about_part{
    position: relative;
    z-index: 99;
}
.about_part{
    padding: 100px 0px;
}
.section_tittle h2 {
    font-size: 24px;
    font-weight: 700;
    position: relative;
    z-index: 1;
    text-transform: capitalize;
    line-height: 24px;
    display: flex;
    padding-left: 195px
}
.section_tittle h2:after {
    position: absolute;
    content: "";
    left: 0;
    top: 12px;
    width: 180px;
    height: 1px;
    background-color: #000000;
}
.about_part .about_img{
    position: relative;
    z-index: 1;
}
.about_img img {
    max-width: 100%;
    height: 42rem;
}
.about_img:after {
    position: absolute;
    content: "";
    /* width: 25%; */
    width: 50%;
    /* height: 35rem; */
    height: 100%;
    /* top: 70rem; */
    top: 55px;
    /* left: 24rem; */
    right: 0;
    background-image: url(../assets/image/about_back.webp);
    z-index: -1;
}
.about_text{
    display: grid;
    justify-content: center;
    align-items: center;
}
.about_text h1{
    font-weight: 600;
    line-height: 3.5rem;
}
.about_part p{
    font-size: 20px;
    line-height: 1.8rem;
    /* overflow: hidden; */
    /* -webkit-box-orient: vertical; */
    display: block;
    /* display: -webkit-box; */
    /* overflow: hidden !important; */
    /* text-overflow: ellipsis; */
    /* -webkit-line-clamp: 6; */
    text-align: justify;
}
@media only screen and (max-width: 640px) {
    .about_part {
        padding: 10px 0px;
    }
    .about_img img {
        max-width: 100%;
        height: 20rem;
    }
    .about_img:after {
        right: 2rem;
        top: 2rem;
    }
    .logo_footer {
        width: 100%;
    }
    .w-sm-100{
        width: 100% !important;
    }
}
</style>