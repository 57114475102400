<template>
    <section class="project_area">
        <div class="w-100 h-100 container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section_title d-flex justify-content-end align-items-center">
                        <h2>
                            <span>PROJECTS</span>
                        </h2>
                    </div>
                    <div class="project_div_comp mb-5">
                            <div class="w-100 h-100 d-flex box-center" v-for="project in showProjects()" :key="project.id">
                                <router-link :to="{name:'single_project', params:{project_id:project.id,project_name:project.name}}" class="project-card_comp " >
                                    <div class="project_card_image_comp h-100 " :style="`background-image:url(./projects/${project.folder}/${project.main_image})`">
                                        <div class="w-100 h-100 d-flex box-center project_card_text_comp">
                                            <h4>{{project.name}}</h4>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                    </div>
                    <div class="w-100 mt-0 mt-md-5">
                        <router-link to="/project" class="mt-2 more_btn p-2">
                            <span class="">More projects</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
</template>
<script>
    import projects from '../../data/projects.json'
    import { Hooper, Slide } from 'hooper';
    import 'hooper/dist/hooper.css';
    export default {
        name:'projects_home',
        components: {Hooper,Slide},
        data() {
            return {
                hover:false,
                hooperSettings: {
                    itemsToShow: 1,
                    centerMode: true,
                    breakpoints: {
                        930: {
                            itemsToShow: 3,
                            centerMode: false,
                        },
                        394: {
                            itemsToShow: 2,
                            centerMode: true,
                        }
                     }
                },
                projectsData : projects.projects,
            }
        },
        
        methods: {
            showProjects(){
                return this.projectsData.slice(0,6);
            }
        }
    }
</script>
<style>
    .project_area{
        padding-top: 5rem;
        margin: 5rem 0;
    }
    .hooper{
        min-height: 100% !important;
    }
    .project_area .section_title h2{
        font-size: 24px;
        font-weight: 700;
        position: relative;
        z-index: 1;
        text-transform: capitalize;
        line-height: 24px;
        display: inline-block;
        margin-bottom: 80px;
        right: 20rem;
    }
    .project_area .section_title h2::after{
        position: absolute;
        top: 12px;
        left: 8rem;
        content: "";
        width: 180px;
        height:1px;
        background-color:black
    }
    
    .project_home_image:hover{
        width:120%;
    }
    .section_show_projects{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
    }
    .project_div_comp{
        display: grid;
        grid-template-columns: repeat(3,23rem);
        gap: 2rem;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
    .project-card_comp{
        /* height: 28rem; */
        height: 28rem;
        transition: all .3s ease;
        width: 23rem;
    }
    .project-card_comp img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .3s ease;
        cursor: pointer;
    }
    .project_card_image_comp .project_card_text_comp{
        background: -webkit-linear-gradient(84deg, #000000d1 0%, rgb(48 48 48 / 66%) 100%);
        transition: all .5s ease;
    }
    .project-card_comp:hover .project_card_image_comp .project_card_text_comp{
        background: -webkit-linear-gradient(76deg, #000000a6 0%, rgb(30 30 30 / 72%) 100%);
        transition: all .5s ease;
    }
    .project-card_comp .project_card_image_comp{
        object-fit: cover;
        transition: object-fit 1s ease;
    }
    .project-card_comp:hover .project_card_image_comp{
        /* background-size: 220%; */
        transition: background-size 1s ease;
    }
    .project_card_text_comp{
        opacity: 0;
        transition: opacity 1s ease;
        /* transition: all .5s ease; */
    }
    .project-card_comp:hover .project_card_text_comp{
        /* display: flex; */
        opacity: 1;
        /* transition: all .5s ease; */
    }
    .project_card_image_comp .project_card_text_comp h4{
        font-weight: 500;
        color: white;
        transition: all .3s ease;
        filter: drop-shadow(rgb(34, 34, 34) 5px 5px 5px);
        letter-spacing: 2px;

    }
    /* .project-card:hover  {
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
    } */
    .project_card_image_comp{
        overflow:hidden;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .project_card_des_comp small{
        width:100%;
        overflow:hidden;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        overflow: hidden !important;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        color: rgb(127, 127, 127);
    }
    .project_card_des_comp p{
        width:100%;
        overflow:hidden;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        overflow: hidden !important;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
    }
    @media only screen and (max-width: 640px) {
    .project_area {
        padding-top: 2rem;
    }
    .project_area .section_title h2 {
        right: 13rem;
    }
    .project_area .section_title h2::after{
        width: 120px;
    }
    .project_area .section_title h2 {
        margin-bottom: 30px;
    }
    /* .project_card .w-100 {
        height: 60%;
    } */
    .project_card {
        height: 25rem;
    }
    .project_div_comp {
        display: flex;
        flex-direction: column;
    }
}
</style>