<template>
    <header class="w-100 navbar_top">
        <div class="w-100 d-flex justify-content-end align-items-center p-2 mb-2" style="background: rgba(0, 0, 0, 0.5)">
            <div class="mx-1">
                <ul class="d-flex box-center nav_ul mx-4">
                    <li>
                        <a href="https://www.facebook.com/archiparsarchitecture" target="_blank">
                            <img src="../assets/icons/facebook_top.png" alt="facebook" style="width:1rem;height:1rem">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/archipars/" target="_blank">
                            <img src="../assets/icons/instagram_top.png" alt="instagram" style="width:1rem;height:1rem">
                        </a>
                    </li>
                    <!-- <li>
                        <a href="https://twitter.com/ArchiPars" target="_blank">
                            <img src="../assets/icons/twitter.png" alt="twitter" style="width:1rem;height:1rem">
                        </a>
                    </li> -->
                    <!-- <li>
                        <a href="https://www.pinterest.com/ArchiParsLLC/" target="_blank">
                            <img src="../assets/icons/pinterest_top.png" alt="pinterest" style="width:1rem;height:1rem">
                        </a>
                    </li> -->
                    <li>
                        <a href="https://www.linkedin.com/company/archipars-llc/" target="_blank">
                            <img src="../assets/icons/linkedin_top.png" alt="linkedin" style="width:1rem;height:1rem;">
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="w-100 d-flex justify-content-between align-items-center px-4 navbar_top_inner">
            <div>
                <router-link to="/">
                    <img src="../assets/logo/AP_Logo.webp" alt="logo" class="logo mx-md-5">
                </router-link>
            </div>
            <button class="text-white menu_btn" @click="openNav()">
                <b-icon icon="justify"></b-icon>
            </button>
            <!-- <div class="mx-4 menu_top" :class="blackNavColor == 'black'?'navText_black':'navText_white'"> -->
            <div class="mx-4 menu_top text-white">
                <!-- <div class="firm_dropdown m-auto">
                    <div class="d-flex box-center p-1" id="firm">
                            <span class="mx-1">FIRM</span>
                            <b-icon icon="caret-down-fill"></b-icon>
                        </div>
                    <div class="firm_dropdown-content">
                        <ul>
                            <li>
                                <router-link id="login" to="#">LOGIN</router-link>
                            </li>
                            <li>
                                <router-link id="team" to="#">TEAM</router-link>
                            </li>
                        </ul>
                    </div>
                </div> -->
                <!-- <b-dropdown variant="transparent" right toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                        <div class="d-flex box-center p-1" id="projects">
                            <span class="mx-1">Projects</span>
                            <b-icon icon="caret-down-fill"></b-icon>
                        </div>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                    <b-dropdown-item href="#">Another action</b-dropdown-item>
                    <b-dropdown-item href="#">Something else here...</b-dropdown-item>
                </b-dropdown> -->
                <router-link class="mx-4 py-2" id="contact_us" to="/contact">CONTACT</router-link>
                <router-link class="mx-4 py-2" id="metaverse" to="/metaverse">METAVERSE</router-link>
                <router-link class="mx-4 py-2" id="about" to="/about">ABOUT</router-link>
                <router-link class="mx-4 py-2" id="insights" to="/insight">INSIGHTS</router-link>
                <router-link class="mx-4 py-2" id="projects" to="/project">PROJECTS</router-link>
                <router-link class="mx-4 py-2" id="career" to="/" >HOME</router-link>

            </div>
        </div>
        <div id="mySidenav" class="sidenav">
            <button class="closebtn" @click="closeNav()">&times;</button>
            <router-link class="mx-4 py-2" id="career" to="/">HOME</router-link>
            <router-link class="mx-4 py-2" id="projects" to="/project">PROJECTS</router-link>
            <router-link class="mx-4 py-2" id="insights" to="/insight">INSIGHTS</router-link>
            <router-link class="mx-4 py-2" id="about" to="/about">ABOUT</router-link>
            <router-link class="mx-4 py-2" id="metaverse" to="/metaverse">METAVERSE</router-link>
            <router-link class="mx-4 py-2" id="contact_us" to="/contact">CONTACT</router-link>

            <!-- <b-dropdown variant="transparent" class="side_dropdown" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="d-flex box-center p-1" id="services">
                        <span class="mx-1">Services</span>
                        <b-icon icon="caret-down-fill"></b-icon>
                    </div>
                </template>
                <b-dropdown-item href="#">Action</b-dropdown-item>
                <b-dropdown-item href="#">Another action</b-dropdown-item>
                <b-dropdown-item href="#">Something else here...</b-dropdown-item>
            </b-dropdown> -->
            <!-- <b-dropdown variant="transparent" class="side_dropdown" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="d-flex box-center p-1" id="firm">
                        <span class="mx-1">FIRM</span>
                        <b-icon icon="caret-down-fill"></b-icon>
                    </div>
                </template>
                <b-dropdown-item href="#">LOGIN</b-dropdown-item>
                <b-dropdown-item href="#">TEAM</b-dropdown-item>
                <b-dropdown-item href="/about">ABOUT</b-dropdown-item>
            </b-dropdown> -->
        </div>
    </header>
</template>

<script>
export default {
    name:'navbar',
    props:['blackNavColor'],
    data(){
        return {
            navColor:false
        }
    },
    mounted() {
        // if (this.$route.name == "career") {
        //     this.navColor = !this.navColor;
        // }
    },
    methods: {
        openNav() {
            document.getElementById("mySidenav").style.width = "250px";
        },

        closeNav() {
            document.getElementById("mySidenav").style.width = "0";
        }
    }
}
</script>

<style>
    #mySidenav{
        height: 100vh;
    }
    .nav_ul li{
        margin: 0 1rem;
    }
    .nav_ul li img{
        object-fit: contain;
    }
    .firm_dropdown {
    position: relative;
    display: inline-block;
    }
    .firm_dropdown-content li:hover a, .firm_dropdown-content li:focus a {
        color:var(--secondColor) !important;
    }
    .firm_dropdown-content {
    display: none;
    position: absolute;
    background: rgba(242, 242, 242, 0.913) !important;
    min-width: 160px;
    padding: 12px 16px;
    z-index: 1;
    border-radius: .5rem;
    right: 0;
    }
    .firm_dropdown-content li a{
        transition: all .3s ease;
    }
    .firm_dropdown-content li {
        display: flex;
        margin: .25rem 0;
    }
    .firm_dropdown{
        cursor: pointer;
    }
    .firm_dropdown:hover .firm_dropdown-content {
    display: block;
    }
    .logo{
        width: 8rem;
        height: 7rem;
        object-fit: contain;
    }
    .navbar_top_inner .menu_btn{
        display: none;
    }
    .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
        border-color: transparent !important;
    }
    .bi-caret-down-fill{
        font-size: 12px !important;
    }
    .btn {
        --bs-btn-padding-x: 0.75rem;
        --bs-btn-padding-y: 0.375rem;
    }
    #career, #projects,#metaverse, #firm,#insights, #contact_us, #about{
        border-bottom: 2px solid transparent;
        font-weight: 500;
        color:white;
        transition: all .3s ease;
        letter-spacing: 2px;
        font-size: 14px;
    }

    #career:hover, #projects:hover, #metaverse:hover, #firm:hover,#insights:hover, #contact_us:hover, #about:hover{
        border-bottom: 2px solid white;
    }
    .navbar_top{
        position: absolute;
        top: 0;
        /* background: rgb(0, 0, 0);
        background: rgba(0, 0, 0, 0.5); */
        color: #f1f1f1;
        z-index: 9999;
    }
    .navbar_top_inner{
        filter: drop-shadow(rgb(90, 90, 90) 3px 6px 5px);
    }
    .dropdown-menu-right{
        background: rgba(0, 0, 0, 0.542) !important;
    }
    .dropdown-item{
        color: #f1f1f1 !important;
        transition: all .3s ease;
    }
    .dropdown-item:hover, .dropdown-item:focus {
        color:var(--secondColor) !important;
        background-color: transparent !important;
    }
    .menu_btn{
        background-color: transparent;
        font-size: 2rem;
    }
    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        background-color: #111;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
    }

    .sidenav a, .sidenav .side_dropdown {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 17px;
        color: #818181;
        display: block;
        transition: 0.3s;
    }

    .sidenav a:hover {
        color: #f1f1f1;
    }

    .sidenav .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
    }
    .side_dropdown .dropdown-menu{
        position: relative !important;
        transform: translate3d(0px, 0px, 0px) !important;
        background-color: transparent;
    }
    .navText_black a,.navText_black span,.navText_black svg{
        color: black !important;
    }
    .navText_black .dropdown-item{
        color:white ;
    }
    .navText_black .dropdown-menu-right {
        background: rgba(255, 255, 255, 0.974) !important;
    }
    .navText_black #career:hover, .navText_black #projects:hover, .navText_black #metaverse:hover, .navText_black #firm:hover,.navText_black #insights:hover, .navText_black #contact_us:hover,.navText_black #about:hover{
        border-bottom: 2px solid black !important;
    }


    .navText_white a,.navText_white span,.navText_white svg{
        color: white !important;
    }
    .navText_white .dropdown-item{
        color:black ;
    }
    .navText_white .dropdown-menu-right {
        background: rgba(53, 53, 53, 0.974) !important;
    }
    .navText_white #career:hover, .navText_white #projects:hover, .navText_white #metaverse:hover, .navText_white #firm:hover,.navText_white #insights:hover, .navText_white #contact_us:hover,.navText_white #insights:hover, .navText_white #about:hover{
        border-bottom: 2px solid white !important;
    }
    .menu_top{
        display: flex;
        flex-direction: row-reverse;
    }
@media only screen and (max-width: 640px) {
    .navbar_top_inner .menu_top{
        display: none;
    }
    .navbar_top_inner .menu_btn{
        display: block;
    }
    #career:hover, #projects:hover, #metaverse:hover, #firm:hover,#insights, #contact_us:hover, #about:hover{
        border-bottom: 2px solid transparent;
    }
}
</style>