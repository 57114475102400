<template>
    <section id="banner_part">
        <div :class="['slide', { 'd-block': currentSlide === 0 }]" class="animate__animated animate__fadeIn">
            <div id="background">
            </div>
            <div class="container justify-content-center align-items-center d-flex animate__animated animate__fadeIn" style="height:100vh" >
                <div class="row w-100 ">
                <div class="col-lg-12 offset-lg-0 col-sm-8 offset-sm-2">
                    <div class="banner_text">
                    <div class="banner_text_inner text-start first_slide">
                        <!-- <span class="banner_text_white">ARCHITECTURAL DESIGN FOR <br> SPACES THAT <span style="color:var(--secondColor);filter:none;font-weight: 700;">INSPIRE CONNECTION</span></span> -->
                        <h1>
                            LIVE
                        </h1>
                        <p> Creating homes that are beautiful and practical prioritizing comfort,<br> functionality, and aesthetics.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div :class="['slide', { 'd-block': currentSlide === 1 }]" class="animate__animated animate__fadeIn">
            <div id="background2">
            </div>
            <div class="container justify-content-start align-items-center d-flex animate__animated animate__fadeIn" style="height:100vh" >
                <div class="row w-100 ">
                <div class="col-lg-12 offset-lg-0 col-sm-8 offset-sm-2">
                    <div class="banner_text">
                    <!-- <div class="banner_text_inner second_slide">
                        <span class="banner_text_white">ARCHITECTURAL DESIGN FOR <br> SPACES THAT <span style="color:var(--secondColor);filter:none;font-weight: 700;">INSPIRE CONNECTION</span></span>
                        <div class="second_slide_inner text-start ">
                            <h3>WORK</h3>
                            <p class="my-0"> Crafting workspaces that are productive and inspiring focusing<br> on a productive and collaborative environment.</p>
                        </div>
                    </div> -->
                    <div class="banner_text_inner text-start first_slide">
                        <!-- <span class="banner_text_white">ARCHITECTURAL DESIGN FOR <br> SPACES THAT <span style="color:var(--secondColor);filter:none;font-weight: 700;">INSPIRE CONNECTION</span></span> -->
                        <h1>
                            WORK
                        </h1>
                        <p> Crafting workspaces that are productive and inspiring focusing<br> on a productive and collaborative environment.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div :class="['slide', { 'd-block': currentSlide === 2 }]" class="animate__animated animate__fadeIn">
            <div id="background3">
            </div>
            <div class="container justify-content-start align-items-center d-flex animate__animated animate__fadeIn" style="height:100vh" >
                <div class="row w-100 ">
                <div class="col-lg-12 offset-lg-0 col-sm-8 offset-sm-2">
                    <div class="banner_text">
                    <!-- <div class="banner_text_inner">
                        <span class="banner_text_white">ARCHITECTURAL DESIGN FOR <br> SPACES THAT <span style="color:var(--secondColor);filter:none;font-weight: 700;">INSPIRE CONNECTION</span></span>
                        <p> Designing gathering spaces that foster community promoting interaction, inclusivity, and accessibility.</p>
                    </div> -->
                    <div class="banner_text_inner text-start first_slide">
                        <!-- <span class="banner_text_white">ARCHITECTURAL DESIGN FOR <br> SPACES THAT <span style="color:var(--secondColor);filter:none;font-weight: 700;">INSPIRE CONNECTION</span></span> -->
                        <h1>
                            CONNECT
                        </h1>
                        <p> Designing gathering spaces that foster community promoting<br> interaction, inclusivity, and accessibility. <br>Architectural design for spaces than inspire connection</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <!-- <div class="slide-selector">
            <button v-for="slide in 3" :key="slide" @click="selectSlide(slide)" :class="{ 'active': currentSlide === slide - 1 }" class="">
                <div class="d-flex box-center">
                    <div class="d-flex justify-content-start align-items-start slideSelector_inner" :id="`btn_${slide}`">
                        <span>{{sildeText(slide)}}</span>
                        <div class="rounded-circle" style="color:var(--secondColor);width: 10px;height: 10px;"></div>
                    </div>
                </div>
            </button>
        </div> -->
        <div class="slide_selector_2">
            <div class="d-flex box-center">
                <button v-for="slide in 3" :key="slide" @click="selectSlide(slide)" :class="{ 'active': currentSlide === slide - 1 }" class="mx-2 rounded-circle d-flex box-center" style="flex-direction:column" :id="`dot_${slide}`" :aria-label="`dot_${slide}`">
                    <div class="slide_btn_inner rounded-circle"></div>
                </button>
            </div>
            
        </div>
    </section>
</template>

<script>
import 'animate.css';
export default {
    name:'banner',
    data(){
        return{
            currentSlide:0,
        }
    },
    mounted(){
        setInterval(this.nextSlide,10000);
    },
    methods:{
        nextSlide() {
            this.currentSlide = (this.currentSlide + 1) % 3;
        },
        selectSlide(slideIndex) {
            this.currentSlide = slideIndex - 1;
        },
        sildeText(slide){
            switch (slide) {
                case 1:
                    return "LIVE"
                case 2:
                    return "WORK"
                case 3:
                    return "CONNECT"
            }
        }
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Anton&family=Lato:wght@100;300;400;700;900&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
    @keyframes backgroundMove {
        0% {
            left: -30%;
        }
        50% {
            left: 0;
        }
        100% {
            left: -30%;
        }
    }
    .slide {
        display: none;
        transition: all button s ease;
    }
    .animate__animated.animate__fadeIn {
        --animate-duration: 2s;
    }

    #background {
    background-image: url("../assets/image/main_image.webp");
    position: absolute;
    height: 100%;
    width: 130%;
    background-size: cover;
    background-position:center;
    z-index: -1;
    animation-name: backgroundMove;
    animation-duration: 200s;
    animation-iteration-count: infinite;
    }
    #background2 {
    background-image: url("../assets/image/main_image_2.webp");
    position: absolute;
    height: 100%;
    width: 130%;
    background-size: cover;
    background-position:center;
    z-index: -1;
    animation-name: backgroundMove;
    animation-duration: 200s;
    animation-iteration-count: infinite;
    }
    #background3 {
    background-image: url("../assets/image/main_image_3.webp");
    position: absolute;
    height: 100%;
    width: 130%;
    background-size: cover;
    background-position:center;
    z-index: -1;
    animation-name: backgroundMove;
    animation-duration: 200s;
    animation-iteration-count: infinite;
    }
    
    .first_slide{
        display: flex;
        flex-direction: column;
        font-family: 'Montserrat', sans-serif;
    }
    .first_slide h1{
        /* filter: drop-shadow(rgb(43, 31, 6) 4px 4px 4px); */
        line-height: 80px;
        letter-spacing: 0px;
        font-size: 60px; 
        font-weight: 900;
    }
    .first_slide p{
        filter: drop-shadow(rgb(34, 34, 34) 5px 5px 5px);
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        margin: 0 !important;
    }
    .second_slide .second_slide_inner {
        display: flex;
        flex-direction: column;
        font-family: 'Montserrat', sans-serif;
        transform: translateY(5rem);
    }
    .second_slide .second_slide_inner h3{
        line-height: 120px;
        letter-spacing: 0px;
        font-size: 45px;
        font-weight: 900;
        transform: translateY(2rem);
    }
    .second_slide .second_slide_inner p{
        line-height: 2rem;
        letter-spacing: 1px;
        font-size: 18px;
    }



    .banner_text{
        display: table;
        width: 100%;
        height: 100%;
        z-index: 99;
        margin-top:25px;
        align-items: center;
    }
    .banner_text_inner{
        /* vertical-align: middle; */
        color:white;
        text-transform: capitalize;
        font-weight: 400;
        line-height: 25px;
    
    }
    .banner_text .banner_text_inner span{
    text-transform: capitalize;
    font-size: 2.3rem;
    line-height:2.5rem;
    color:white;
    font-weight: 900;
    margin-bottom: 1rem;
    letter-spacing: 4px;

    }
    .banner_text .banner_text_inner .banner_text_white{
        filter: drop-shadow(rgb(34, 34, 34) 5px 5px 5px);

    }
    .banner_text .banner_text_inner p{
    line-height:2.5rem;
    letter-spacing: 2px;
    font-size: 20px;
    margin-top: 1.5rem;

    }
    #banner_part::after{
    position: absolute;
    right: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    background: -webkit-linear-gradient(76deg, #000000a6 0%, rgb(255 255 255 / 12%) 100%);
    }
    .slide_selector_2{
        width: 100%;
        position: absolute;
        transform: translateY(-3rem);
    }
    .slide_selector_2 button {
        background-color: #dddddd80;
        width: 15px;
        height: 15px;
        border: 2px solid transparent;
        transition: all .3s ease;
    }
    .slide_selector_2 button.active{
        background-color: transparent;
        width: 20px;
        height: 20px;
        border: 2px solid var(--secondColor);
        /* font-size: 1.3rem; */
    }
    .slide_selector_2 button .slide_btn_inner{
        width: 5px;
        height: 5px;
        
    }
    .slide_selector_2 button.active .slide_btn_inner{
        background-color: var(--secondColor);
        width: 10px;
        height: 10px;
    }





    .slide-selector{
        /* position: absolute;
        transform: translate(8rem, -8rem); */
        width: 100%;
        position: absolute;
        transform: translateY(-5rem);
    }
    
    .slide-selector button{
        background-color: transparent;
        color: white;
        font-size: 1rem;
        margin: 0 .5rem;
        font-family: 'Anton', sans-serif;
        font-family: 'Lato', sans-serif;
        transition: all .3s ease;
    }
    .slide-selector button span{
        font-weight: 700;
        letter-spacing: 3px;
    }
    /* .slide-selector button #btn_1::after,.slide-selector button #btn_2::after{
        content: "";
        border: 1px solid white;
        height: 1.2rem;
        transform: translate(.8rem,.2rem);
    } */
    .slide-selector button.active,.slide-selector button.active small{
        color:var(--secondColor) !important;
        /* font-size: 1.3rem; */
    }
    .slide-selector button.active span{
        font-weight: 900;
    }
    .slide-selector button.active small{
        font-weight: 700;
    }
    .slide-selector button .slide_number{
        border-left: 2px solid transparent;
    }
    .slide-selector button.active .slide_number{
        border-left: 2px solid var(--secondColor);
        padding: 0 .5rem;
    }
    @media only screen and (max-width: 640px) {
        .banner_text .banner_text_inner p {
            line-height: 1.5rem;
            letter-spacing: 2px;
            font-size: 15px;
            margin-top: 1.5rem;
        }
        .slide_selector_2{
            width: 100%;
            position: absolute;
            transform: translateY(-11rem);
        }
        .slide_selector_2 button {
            background-color: #dddddd80;
            width: 10px;
            height: 35px;
            border: 2px solid transparent;
            transition: all .3s ease;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .slide_selector_2 button.active{
            background-color: transparent;
            width: 10px;
            height: 35px;
            border: 2px solid var(--secondColor);
            /* font-size: 1.3rem; */
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .slide_selector_2 button .slide_btn_inner{
            width: 15px;
            height: 15px;
            
        }
        .slide_selector_2 button.active .slide_btn_inner{
            background-color: var(--secondColor);
            width: 15px;
            height: 15px;
        }
    }

</style>
