import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    props: true,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    props: true,
  },
  {
    path: '/metaverse',
    name: 'metaverse',
    component: () => import(/* webpackChunkName: "metaverse" */ '../views/Metaverse.vue'),
    props: true,
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
    props: true,
  },
  {
    path: '/insight',
    name: 'insight',
    component: () => import(/* webpackChunkName: "insight" */ '../views/Insight.vue'),
    props: true,
  },
  {
    path: '/article/:id',
    name: 'article',
    component: () => import(/* webpackChunkName: "article" */ '../views/Article.vue'),
    props: true,
  },
  {
    path: '/service/',
    name: 'service',
    component: () => import(/* webpackChunkName: "service" */ '../views/Service.vue'),
    props: true,
  },
  {
    path: '/service/:service_id/:service_name',
    name: 'single_service',
    component: () => import(/* webpackChunkName: "Single_service" */ '../views/Single_service.vue'),
    props: true,
  },
  {
    path: '/project/:project_type/:project_id/:project_name',
    name: 'single_project',
    component: () => import(/* webpackChunkName: "Single_project" */ '../views/Single_project.vue'),
    props: true,
  },
  {
    path: '/project',
    name: 'projects',
    component: () => import(/* webpackChunkName: "project" */ '../views/Projects.vue'),
    props: true,
  },
  {
    path: '/project/live',
    name: 'live_projects',
    component: () => import(/* webpackChunkName: "Live_projects" */ '../views/Live_projects.vue'),
    props: true,
  },
  {
    path: '/project/work',
    name: 'work_projects',
    component: () => import(/* webpackChunkName: "Work_projects" */ '../views/Work_projects.vue'),
    props: true,
  },
  {
    path: '/project/connect',
    name: 'connect_projects',
    component: () => import(/* webpackChunkName: "Connect_projects" */ '../views/Connect_projects.vue'),
    props: true,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    props: true,
  },
  {
    path: '/:catchAll(.*)',
    name: 'not_found',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/Not_found.vue')
  },
  {
    path: '/notfound',
    name: 'not_found',
    component: () => import(/* webpackChunkName: "notfound" */ '../views/Not_found.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not_found',
    component: () => import('../views/Not_found.vue')
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
})

export default router
