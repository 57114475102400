<template>
    <section class="contact_part overlay contact-cover">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 mx-auto text-center contact">
                    <h1 class=" mb-4" data-aos="zoom-in" data-aos-duration="1500">Let’s Connect</h1>
                    <p class="" data-aos="zoom-out" data-aos-delay="1000">Above all else, it’s our clients’ vision that inspires our designs. We’d love to hear about your own ideas and aspirations so we can help transform them into a reality.</p>
                </div>
                <div class="w-100 d-flex box-center mt-5 contact_us_btn">
                    <router-link to="/contact" class="p-2" >Contact us</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name:'contact',
}
</script>

<style>
.contact_part {
    padding: 9rem 0;
}
.contact-cover.overlay {
    position: relative;
}
.contact-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-image: url('../assets/image/contact.webp');
}
/* .contact-cover.overlay:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background: -webkit-linear-gradient(69deg, #0000005e 0%, rgb(255 255 255 / 12%) 100%);
} */
.contact{
    color: white;
}
.contact h1{
    font-weight: bolder;
    font-size: 3rem;
    letter-spacing: .4rem;
}
.contact p{
    line-height: 3rem;
    font-size: 1.2rem;
    font-weight: 500;
}
.contact_us_btn a{
    color: white;
    border-bottom: 2px solid transparent;
    font-size: 1.3rem;
    transition: all .3s ease;
}
.contact_us_btn a:hover{
    color: white;
    border-bottom: 2px solid white;
    font-size: 1.3rem;
}
@media only screen and (max-width: 640px) {
    .contact-cover {
        background-attachment:local;
    }
}
</style>